import { createGlobalStyle } from "styled-components"

const ColorPalette = `
  :root {
    // color palette
    --off-white: #f4f4f4;
    --offer-white: #e4e4e4;
    --accent-orange: #e35524;
    --off-white-inversion: #060606;
    --off-dark: #3b3a3b;
    --alert-red: #d82a33;
    --alert-yellow: #f5d840;
  }
`

const DefaultTheme = `
  body {
    background-color: var(--off-white);
    font-family: "Noto Sans KR", sans-serif;
    margin: 0;
  }
`

const GlobalStyles = createGlobalStyle`
  ${ColorPalette}
  ${DefaultTheme}
`

export default GlobalStyles