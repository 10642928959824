import React from "react"
import styled from "styled-components"
import GithubGlyph from "../components/glyphs/github"

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 2px dotted var(--off-dark);
  padding-top: 1rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
  width: 100%;
`

const GlyphContainer = styled.div`
  margin-right: 15px;
`

const Footer = () => {
  return (
    <FooterContainer>
      <GlyphContainer>
        <GithubGlyph/>
      </GlyphContainer>
    </FooterContainer>
  )
}

export default Footer