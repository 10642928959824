import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const LogoContainer = styled.div`
  width: ${props => (props.width ? props.width : "auto")};
`

const GithubGlpyh = () => {
  // change this so that it loads dark and light versions of it later
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "github_dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 30) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <LogoContainer width="30px">
      <a href="https://github.com/hikchoi">
        <Img fluid={data.placeholderImage.childImageSharp.fluid}></Img>
      </a>
    </LogoContainer>
  )
}

export default GithubGlpyh