import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const LogoContainer = styled.div`
  width: ${props => (props.width ? props.width : "auto")};
`

// TODO this module is not quite DRY right now learn graphql properly

const StickyLogo = props => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo_sticky_white.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (!data?.placeholderImage?.childImageSharp?.fluid) {
    return <div>Logo not found </div>
  }

  return (
    <LogoContainer width={props.width}>
      <Link to="/">
        <Img fluid={data.placeholderImage.childImageSharp.fluid}></Img>
      </Link>
    </LogoContainer>
  )
}

export default StickyLogo;