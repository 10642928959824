import React from "react"
import GlobalStyles from "../styles/globalStyles"
import Fonts from "../styles/fonts/fonts"
import styled from "styled-components"

import Header from "../components/header"
import Footer from "../components/footer"

const LayoutContainer = styled.div`
  margin: 1rem;
  margin-left: 4rem;
  margin-right: 4rem;
  padding: 1rem;
  margin-bottom: 0;
  padding-bottom: 0;
  max-width: 600px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`

const Layout = ({children}) => {
  return (
    <LayoutContainer>
      <GlobalStyles/>
      <Fonts/>
      <Header/>
      {children}
      <Footer/>
    </LayoutContainer>
  )
}

export default Layout